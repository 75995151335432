header.estela-header {
  box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.1);
  color: black;

  background-image: linear-gradient(25deg,
      hsl(0deg, 0%, 100%) 15%,
      hsl(258deg, 100%, 98%) 54%,
      hsl(258deg, 100%, 96%) 69%,
      hsl(259deg, 100%, 93%) 79%,
      hsl(259deg, 100%, 91%) 86%,
      hsl(260deg, 100%, 88%) 92%,
      hsl(260deg, 100%, 85%) 95%,
      hsl(261deg, 100%, 82%) 98%,
      hsl(262deg, 100%, 79%) 100%,
      hsl(263deg, 100%, 75%) 101%,
      hsl(264deg, 100%, 70%) 102%,
      hsl(266deg, 100%, 64%) 100%);

  .section-title {
    color: #000000;
  }

  .section-sub-title {
    font-size: $font-size-24;
    font-weight: 400;
    color: #000000;
    line-height: 33.57px;

    @media (min-width: 768px) {
      font-size: $font-size-28;
    }
  }

  .section-text {
    line-height: 24.2px;
  }

  .section-button {
    font-size: $font-size-16;
    font-weight: 400;
    line-height: 27.28px;

    @media (min-width: 768px) {
      font-size: $font-size-20;
    }

    border-radius: 10px;
  }
}

.clients-section-title {
  font-size: $font-size-24;
  font-weight: 400;
  color: #AEABB4;
  line-height: 24.72px;
}

section#why-estela {
  .rounded-box {
    // To align the button to the right
    display: flex;
    flex-direction: column;

    .box-button-container {
      // To align the button to the right
      margin-top: auto;
      text-align: right;

      .box-button {
        font-size: $font-size-12;
        font-weight: 400;
        line-height: 20.46px;

        @media (min-width: 768px) {
          font-size: $font-size-16;
        }

        border-radius: 10px;
      }
    }
  }
}

section#estela-get-started {
  background-color: $secondary-purple;

  .section-title {
    font-size: $font-size-50;
    font-weight: 400;
    line-height: 56.65px;

    @media (min-width: 768px) {
      font-size: $font-size-54;
    }
  }

  .section-button {
    font-size: $font-size-16;
    font-weight: 700;
    line-height: 26.04px;

    @media (min-width: 768px) {
      font-size: $font-size-20;
    }

    padding: 0.625rem 1.25rem;
    border-radius: 14px;
    box-shadow: 0px 4px 4px 0px #62626240;
  }

  .top-left-icons {
    top: 0;
    left: 0;
  }
  
  .bottom-right-icons {
    bottom: 0;
    right: 0;
  }

  .purple-icon {
    color: $primary-purple;
  }

  .white-icon {
    color: white;
  }

  .big-nut-icon {
    font-size: 4rem;

    @media (min-width: 576px) {
      font-size: 5rem;
    }

    @media (min-width: 768px) {
      font-size: 7rem;
    }
  }

  .medium-nut-icon {
    font-size: 3rem;

    @media (min-width: 576px) {
      font-size: 3.5rem;
    }

    @media (min-width: 768px) {
      font-size: 4rem;
    }
  }

  .small-nut-icon {
    font-size: 2rem;

    @media (min-width: 576px) {
      font-size: 2.5rem;
    }

    @media (min-width: 768px) {
      font-size: 3rem;
    }
  }
}

section#estela-benefits {
  .section-title {
    font-size: $font-size-18;
    line-height: 1.5;

    @media (min-width: 371px) {
      font-size: $font-size-20;
    }

    @media (min-width: 576px) {
      font-size: $font-size-36;
    }

    @media (min-width: 992px) {
      font-size: $font-size-40;
      line-height: 52.08px;
    }
  }

  .big-section-title {
    font-size: $font-size-42;
    font-weight: 500;
    color: #B8B8B8;

    @media (min-width: 371px) {
      font-size: $font-size-44;
    }

    @media (min-width: 576px) {
      font-size: $font-size-60;
    }

    @media (min-width: 992px) {
      font-size: $font-size-64;
    }
  }

  .benefits-table {
    td {
      text-align: center;
      color: #333533;
      /* Horizontal line separating the rows */
      border-bottom: 3px solid #D9D9D9;

      .item-title {
        font-size: $font-size-20;
        font-weight: 700;
        line-height: 31.25px;

        @media (min-width: 768px) {
          font-size: $font-size-24;
        }
      }

      .item-text {
        font-size: $font-size-16;
        font-weight: 400;
        line-height: 26.04px;

        @media (min-width: 768px) {
          font-size: $font-size-20;
        }
      }
    }

    tr:first-child td {
      /* Remove the horizontal line before the first row */
      border-top: none;
    }

    tr:last-child td {
      /* Remove the horizontal line from the last row */
      border-bottom: none;
    }

    .light-purple-icon {
      font-size: $font-size-36;
      color: $secondary-purple;

      @media (min-width: 576px) {
        font-size: $font-size-48;
      }

      @media (min-width: 768px) {
        font-size: $font-size-64;
      }
    }
  }
}

section#estela-technical-features {
  .section-title {
    color: #2A3833;
  }

  i.check-circle-icon {
    font-size: $font-size-32;
    color: #C89EFF;
  }
}

section#estela-latest-news {
  .date {
    font-weight: 400;
    font-size: $font-size-20;
    line-height: 32.81px;
    color: #1E1E1E;

    @media (min-width: 768px) {
      font-size: $font-size-24;
    }
  }

  .commit {
    font-weight: 500;
  }
}

section#estela-technical-articles {
  background-color: #F8F8F8;

  .separator {
    width: 90%;

    @media (min-width: 992px) {
      width: 70%;
    }
  }

  .rounded-box {
    background-color: #FFFFFF;
    max-width: 653px;
    min-height: 109px;

    .box-title {
      font-weight: 600;
      color: #333533;
      line-height: 29.05px;
    }

    .section-button {
      padding: 0.625rem 1.5rem;
      font-size: $font-size-14;
      font-weight: 600;
      min-width: 122px;
      min-height: 39px;
    }
  }

  .left-design {
    left: 0;
    top: 30%;

    img {
      width: 50%;
      height: auto;

      @media (min-width: 1200px) {
        width: 80%;
      }
    }
  }

  .right-design {
    right: 0;
    bottom: 20%;
    transform: scaleX(-1);

    img {
      width: 50%;
      height: auto;

      @media (min-width: 1200px) {
        width: 80%;
      }
    }
  }
}

.section-title {
  font-size: $font-size-44;
  font-weight: 500;
  line-height: 62.5px;
  color: #333533;

  @media (min-width: 768px) {
    font-size: $font-size-48;
  }
}

.rounded-box {
  border-radius: 10px;
  box-shadow: -3px 4px 9px 0px #00000040;
  border: 5px solid #FFFFFF;
}

.dot-pattern-container {
  position: relative;
  width: 100vw;
  height: 100%;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  background: url('../../assets/img/dots.png') repeat-x;
  background-size: auto 100%;
}

.separator {
  border: 1px solid #D9D9D9;
  width: 100%;
}

.box-text {
  font-size: $font-size-16;
  font-weight: 400;
  line-height: 26.04px;
  color: #333533;

  @media (min-width: 768px) {
    font-size: $font-size-20;
  }
}

.box-title {
  font-size: $font-size-20;
  font-weight: 700;
  color: #1E1E1E;
  line-height: 31.25px;

  @media (min-width: 768px) {
    font-size: $font-size-24;
  }
}

.section-text {
  font-size: $font-size-16;
  font-weight: 400;
  color: #000000;
  line-height: 26.04px;

  @media (min-width: 768px) {
    font-size: $font-size-20;
  }
}