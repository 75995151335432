// Button Styles
.btn {
  @include heading-font;
  font-weight: 700;
}

.btn-sm{
  margin-top: 10px;
}

.btn-xl {
  font-size: 18px;
  padding: 20px 40px;
}

.btn-deactivated {
  font-weight: 400 !important;
  border-color: $gray-900 !important;
  color: $gray-800 !important;
  margin-top: 10px;
  border-radius: 93px;
}

.btn-primary {
  background-color: white;
  border-color: $primary !important;
  border-radius: 93px;
  color: $primary;
  &:active,
  &:focus,
  &:hover {
    color: white;
    border-color: white !important;
    background-color: $primary;
  }
  &:active,
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(254, 209, 55,.5) !important;
  }
}

.btn-purple {
  background-color: $primary-purple;
  border-radius: 14px;
  color: white;
  &:active,
  &:focus,
  &:hover {
    background-color: #77BFA3;
    color: white;
  }
  &:active,
  &:focus {
    background-color: #087E8B;
    box-shadow: none;
  }
}

.btn-black {
  padding: 10px 24px;
  gap: 25px;
  display: flex;
  width: 185px;
  height: 43px;
  align-items: center;
  justify-content: center;
  background-color: black;  /* Color de fondo negro */
  border-radius: 14px;
  color: white;  /* Color de letras blancas */
  &:active,
  &:focus,
  &:hover {
    background-color: #77BFA3;  /* Color de fondo al pasar el cursor o al estar activo/focalizado */
    color: white;
  }
  &:active,
  &:focus {
    background-color: #087E8B;  /* Color de fondo al estar activo o focalizado */
    box-shadow: none;
  }
}

.cta {
  .mail {
    margin-top: 8px;
    .link {
      color: white;
      font-weight: bold;
      text-decoration: underline;
    }
  }
}
.force-primary {
  .cta {
    .mail {
      .link {
	color: #6c757d;
      }
      p {
	color: #6c757d;
      }
    }
  }
}
