@charset "utf-8";
// Core variables and mixins
@import "base/variables.scss";
@import "base/mixins.scss";

// Format text
@import "base/text.scss";

// Custom colors
$primary: #fed136 !default;
$white: #fff !default;
$black: #000 !default;

// Custom Images
$header-image: "../img/background.png";
$contact-image: "../img/map-image.png";

// Global CSS
@import "base/page.scss";
@import "sass-code-highlight/_default";

// Components
@import "components/buttons.scss";
@import "components/navbar.scss";
@import "components/carousels.scss";
@import "components/new-form.scss";
@import "components/new-navbar.scss";
@import "components/client-scroll.scss";

// Layout
@import "layout/masthead.scss";
@import "layout/new-masthead.scss";
@import "layout/services.scss";
@import "layout/images.scss";
@import "layout/team.scss";
@import "layout/contact.scss";
@import "layout/footer.scss";
@import "layout/free-dataset.scss";
@import "layout/blog.scss";
@import "layout/timeline.scss";
@import "layout/web-scraping-for.scss";
@import "layout/estela.scss";
@import "layout/ecommerce.scss";
