#articles-carousel {
  @media (max-width: 576px) {
    margin: 4%;
  }

  .carousel-control-prev,
  .carousel-control-next {
    width: 5%;
  }

  .carousel-control-prev {
    margin-left: -4%;
  }

  .carousel-control-next {
    margin-right: -4%;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    .carousel-inner .active.col-sm-6.carousel-item+.carousel-item+.carousel-item {
      position: absolute;
      top: 0;
      right: -50%;
      z-index: -1;
      display: block;
      visibility: visible;
    }
    .carousel-inner .active.col-md-6.carousel-item+.carousel-item+.carousel-item {
      position: absolute;
      top: 0;
      right: -50%;
      z-index: -1;
      display: block;
      visibility: visible;
    }
  }
  
  @media (min-width: 992px) {
    .carousel-inner .active.col-lg-4.carousel-item+.carousel-item+.carousel-item+.carousel-item {
      position: absolute;
      top: 0;
      right: -33.3333%;
      z-index: -1;
      display: block;
      visibility: visible;
    }
  }
  
  @media (min-width: 576px) {
    .carousel-item {
      margin-right: 0;
    }
  
    .carousel-inner .active+.carousel-item {
      display: block;
    }
  
    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)+.carousel-item {
      transition: none;
    }
  
    .carousel-inner .carousel-item-next {
      position: relative;
      transform: translate3d(0, 0, 0);
    }
  
    .active.carousel-item-left+.carousel-item-next.carousel-item-left,
    .carousel-item-next.carousel-item-left+.carousel-item,
    .carousel-item-next.carousel-item-left+.carousel-item+.carousel-item {
      position: relative;
      transform: translate3d(-100%, 0, 0);
      visibility: visible;
    }
  
    .carousel-inner .carousel-item-prev.carousel-item-right {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      display: block;
      visibility: visible;
    }
  
    .active.carousel-item-right+.carousel-item-prev.carousel-item-right,
    .carousel-item-prev.carousel-item-right+.carousel-item,
    .carousel-item-prev.carousel-item-right+.carousel-item+.carousel-item {
      position: relative;
      transform: translate3d(100%, 0, 0);
      visibility: visible;
      display: block;
      visibility: visible;
    }
  }
  
  @media (min-width: 992px) {
    .carousel-inner .active+.carousel-item+.carousel-item {
      display: block;
    }
  
    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)+.carousel-item+.carousel-item {
      transition: none;
    }
  
  
    .carousel-inner .carousel-item-next {
      position: relative;
      transform: translate3d(0, 0, 0);
    }
  
    .carousel-item-next.carousel-item-left+.carousel-item+.carousel-item+.carousel-item {
      position: relative;
      transform: translate3d(-100%, 0, 0);
      visibility: visible;
    }
  
    .carousel-item-prev.carousel-item-right+.carousel-item+.carousel-item+.carousel-item {
      position: relative;
      transform: translate3d(100%, 0, 0);
      visibility: visible;
      display: block;
      visibility: visible;
    }
  } 
}


#clients-carousel {
  .carousel-item {
    transition: transform 0.8s ease-in-out;
  }

  .carousel-control-prev,
  .carousel-control-next {
    color: $primary;
    font-size: 1.8rem;
    transition: transform 0.5s ease;

    @media (min-width: 576px) {
      width: 1%;
    }
  }

  .carousel-control-prev:hover {
    transform: translateX(-6px);
  }

  .carousel-control-next:hover {
    transform: translateX(6px);
  }

  @media (min-width: 576px) and (max-width: 767px) {
    .carousel-inner .active.col-sm-6.carousel-item+.carousel-item+.carousel-item {
      position: absolute;
      top: 0;
      right: -50%;
      z-index: -1;
      display: block;
      visibility: visible;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .carousel-inner .active.col-md-4.carousel-item+.carousel-item+.carousel-item+.carousel-item {
      position: absolute;
      top: 0;
      right: -33.3333%;
      z-index: -1;
      display: block;
      visibility: visible;
    }
  }

  @media (min-width: 992px) {
    .carousel-inner .active.col-lg-3.carousel-item+.carousel-item+.carousel-item+.carousel-item+.carousel-item {
      position: absolute;
      top: 0;
      right: -25%;
      z-index: -1;
      display: block;
      visibility: visible;
    }
  }

  @media (min-width: 576px) {
    .carousel-item {
      margin-right: 0;
    }

    .carousel-inner .active+.carousel-item {
      display: block;
    }

    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)+.carousel-item {
      transition: none;
    }

    .carousel-inner .carousel-item-next {
      position: relative;
      transform: translate3d(0, 0, 0);
    }

    .carousel-inner .carousel-item-prev.carousel-item-right {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      display: block;
      visibility: visible;
    }

    .active.carousel-item-left+.carousel-item-next.carousel-item-left,
    .carousel-item-next.carousel-item-left+.carousel-item,
    .carousel-item-next.carousel-item-left+.carousel-item+.carousel-item {
      position: relative;
      transform: translate3d(-100%, 0, 0);
      visibility: visible;
    }

    .active.carousel-item-right+.carousel-item-prev.carousel-item-right,
    .carousel-item-prev.carousel-item-right+.carousel-item,
    .carousel-item-prev.carousel-item-right+.carousel-item+.carousel-item {
      position: relative;
      transform: translate3d(100%, 0, 0);
      visibility: visible;
      display: block;
      visibility: visible;
    }
  }

  @media (min-width: 768px) {
    .carousel-inner .active+.carousel-item+.carousel-item {
      display: block;
    }

    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)+.carousel-item+.carousel-item {
      transition: none;
    }

    .carousel-inner .carousel-item-next {
      position: relative;
      transform: translate3d(0, 0, 0);
    }

    .carousel-item-next.carousel-item-left+.carousel-item+.carousel-item+.carousel-item {
      position: relative;
      transform: translate3d(-100%, 0, 0);
      visibility: visible;
    }

    .carousel-item-prev.carousel-item-right+.carousel-item+.carousel-item+.carousel-item {
      position: relative;
      transform: translate3d(100%, 0, 0);
      visibility: visible;
      display: block;
      visibility: visible;
    }
  }

  @media (min-width: 992px) {
    .carousel-inner .active+.carousel-item+.carousel-item+.carousel-item {
      display: block;
    }

    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)+.carousel-item+.carousel-item+.carousel-item {
      transition: none;
    }

    .carousel-inner .carousel-item-next {
      position: relative;
      transform: translate3d(0, 0, 0);
    }

    .carousel-item-next.carousel-item-left+.carousel-item+.carousel-item+.carousel-item+.carousel-item {
      position: relative;
      transform: translate3d(-100%, 0, 0);
      visibility: visible;
    }

    .carousel-item-prev.carousel-item-right+.carousel-item+.carousel-item+.carousel-item+.carousel-item {
      position: relative;
      transform: translate3d(100%, 0, 0);
      visibility: visible;
      display: block;
      visibility: visible;
    }
  }
}
