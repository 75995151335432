#newNav {
  @media(max-width:991px) {
    background-color: white;
    box-shadow: 2px 2px 10px 0 black;
  }

  .navbar-toggler {
    font-size: 12px;
    right: 0;
    padding: 13px;
    text-transform: uppercase;
    color: white;
    border: 0;
    background-color: $primary;
    @include heading-font;
  }

  .drop-item {
    font-size: 78%;
    font-weight: 400;
    letter-spacing: 1px;
    color: $gray-900;
    background-color: white;
    border: 1px;

    &:hover {
      cursor: pointer;
      color: white;
      background-color: $primary;
    }
  }

  .drop-box {
    background-color: white;
  }

  .navbar-brand {
    .bm-logo {
      height: 30px;
      width: 200px;
      background-image: url("../img/logo-original.png");
      background-size: contain;
      background-repeat: no-repeat;
      display: block;
    }

    color: $primary;
    @include script-font;

    &.active,
    &:active,
    &:focus,
    &:hover {
      color: darken($primary, 10%);
    }
  }

  .navbar-nav {
    .nav-item {
      .nav-link {
        font-size: 88%;
        font-weight: 400;
        padding: 0.75em 0;
        letter-spacing: 1px;
        color: $gray-900;
        @include heading-font;

        &.active,
        &:hover {
          color: $primary;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
  }
}

@media(min-width:992px) {
  #newNav {
    box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.5);
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-transition: padding-top 0.3s, padding-bottom 0.3s;
    -moz-transition: padding-top 0.3s, padding-bottom 0.3s;
    transition: padding-top 0.3s, padding-bottom 0.3s;
    border: none;
    background-color: transparent;

    .navbar-brand {
      font-size: 1.75em;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      transition: all 0.3s;

      .bm-logo {
        height: 30px;
        width: 200px;
        background-image: url("../img/logo-original.png");
        background-size: contain;
        background-repeat: no-repeat;
        display: block;
      }
    }

    .navbar-nav {
      .nav-item {
        .nav-link {
          padding: 1.1em 1em !important;
          color: black;

          &:hover {
            color: black;
            font-weight: bold;
          }
        }
      }
    }

    &.navbar-shrink {
      padding-top: 0;
      padding-bottom: 0;
      background-color: white;
      box-shadow: 2px 2px 10px 0 black;

      .navbar-brand {
        font-size: 1.25em;
        padding: 15px 0;

        .bm-logo {
          background-image: url("../img/logo-original.png");
        }
      }

      .nav-btn {
        color: white;
        background-color: $primary;

        &:hover {
          color: $primary;
          border-color: $primary !important;
          background-color: white;
        }
      }

      .navbar-nav {
        .nav-item {
          .nav-link {
            color: $gray-900;

            &.active,
            &:hover {
              color: $primary;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

.general-navbar {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  background-color: white !important;
  box-shadow: 2px 2px 10px 0 black;

  .navbar-nav {
    .nav-item {
      .nav-link {
        color: $gray-900 !important;

        &:hover {
          color: $primary !important;
          font-weight: bold;
        }
      }

      .nav-btn {
        color: white;
        background-color: $primary;

        &:hover {
          color: $primary !important;
          border-color: $primary !important;
          background-color: white;
        }
      }
    }
  }

  &.navbar-shrink {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .navbar-brand {
    .bm-logo {
      background-image: url("../img/logo-original.png") !important;
    }
  }
}