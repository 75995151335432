// Variables
$primary: rgba(0, 191, 113, 1);
$logo: rgb(255, 87, 51);
$primary-purple: #9747FF;
$secondary-purple: #ECDEFF;


$font-size-10: 0.625rem;
$font-size-12: 0.75rem;
$font-size-14: 0.875rem;
$font-size-16: 1rem;
$font-size-18: 1.125rem;
$font-size-20: 1.25rem;
$font-size-22: 1.375rem;
$font-size-24: 1.5rem;
$font-size-26: 1.625rem;
$font-size-28: 1.75rem;
$font-size-32: 1.875rem;
$font-size-36: 2.25rem;
$font-size-40: 2.5rem;
$font-size-42: 2.625rem;
$font-size-44: 2.75rem;
$font-size-48: 3rem;
$font-size-50: 3.125rem;
$font-size-54: 3.375rem;
$font-size-60: 3.75rem;
$font-size-64: 4rem;


// Restated Bootstrap Variables
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
