// Images

.auto-gray-scale {
  filter: grayscale(90%);
  opacity: 0.5;
  &:hover {
    filter: grayscale(0%);
    opacity: 1.0;
  }
}

.img-grid {
  height: 90%;
}

// Grids

.pad-card {
  padding: 20px;
}
