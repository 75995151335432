.gray-background {
    background-color: #F6F6F6;
}

.green-text {
    color: #00BF71 !important;
}

.orange-text {
    color: #FF5F3C;
}

.purple-text {
    color: $primary-purple !important;
}

.white-text {
    color: #ffffff;
}

.background-color-2 {
    background-color: #2a3833;
}

.title-text-1 {
    font-size: 50px;
}

.title-text-2 {
    font-size: 36px;
}

.title-text-3 {
    font-weight: 500;
    font-size: 32px;
}

.big-text {
    font-size: 40px;
}

.normal-text {
    font-size: 20px;
}

.small-text {
    font-size: 16px;
}

.little-text {
    font-size: 13px;
}

.bold-text {
    font-weight: 700;
}

.inter-font {
    font-family: 'Inter', sans-serif !important;
}

.dm-sans-font {
    font-family: 'DM Sans', sans-serif !important;
}

.syne-font {
    font-family: 'Syne', sans-serif !important;
}

.chau-philomene-one-font {
    font-family: 'Chau Philomene One', sans-serif !important;
}

.nunito-sans-font {
    font-family: 'Nunito Sans', sans-serif !important;
}

.inria-sans-font {
    font-family: 'Inria Sans', sans-serif;
    font-weight: 400;
}

.questrial-font {
    font-family: 'Questrial', sans-serif !important;
    font-weight: 400;
}
