.timeline {
  position: relative;
  padding: 0;
  list-style: none;
  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 40px;
    width: 2px;
    margin-left: -1.5px;
    content: "";
    background-color: $gray-200;
  }
  > li {
    position: relative;
    min-height: 50px;
    margin-bottom: 30px;
    &:after,
    &:before {
      display: table;
      content: " ";
    }
    &:after {
      clear: both;
    }
    .timeline-panel {
      position: relative;
      float: right;
      width: 100%;
      padding: 0 20px 0 100px;
      text-align: left;
      &:before {
        right: auto;
        left: -15px;
        border-right-width: 15px;
        border-left-width: 0;
      }
      &:after {
        right: auto;
        left: -14px;
        border-right-width: 14px;
        border-left-width: 0;
      }
    }
    .timeline-image {
      position: absolute;
      z-index: 100;
      left: 0;
      width: 62px;
      height: 62px;
      margin-left: 8px;
      text-align: center;
      color: $white;
      border: 7px solid $gray-200;
      border-radius: 100%;
      background-color: $primary;
      h4 {
        font-size: 10px;
        line-height: 14px;
        margin-top: 12px;
      }
    }
    &.timeline-inverted > .timeline-panel {
      float: right;
      padding: 0 20px 0 100px;
      text-align: left;
      &:before {
        right: auto;
        left: -15px;
        border-right-width: 15px;
        border-left-width: 0;
      }
      &:after {
        right: auto;
        left: -14px;
        border-right-width: 14px;
        border-left-width: 0;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .timeline-heading {
    h4 {
      margin-top: 0;
      color: inherit;
      &.subheading {
        text-transform: none;
      }
    }
  }
  .timeline-body {
    font-size: 1.05em;
    > ul,
    > p {
      margin-bottom: 0;
    }
  }
}
.pixel-font {
  font-family: "PixelGosub";
}
