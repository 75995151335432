header.ecommerce-header {
    box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.1);
    color: black;

    .section-title {
        color: #000000;
        font-size: $font-size-32;
        line-height: 30px;
        margin-bottom: 30px;
    }

    .ecommerce-section-subtitle {
        font-size: $font-size-16;
        font-weight: 400;
        color: #000000;
        line-height: 26.04px;
        margin-bottom: 30px;

        @media (min-width: 768px) {
            font-size: $font-size-20;
        }
    }

    .section-text {
        line-height: 24.2px;
    }

    .gradient-text {
        font-size: 53px;
        font-weight: bold;
        background: linear-gradient(89.98deg, #FF5733 15.79%, #00BF71 58.87%);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;

        @media (min-width: 768px) {
            font-size: 60px;
        }
    }

    .section-button {
        font-size: $font-size-16;
        font-weight: 400;
        line-height: 27.28px;

        @media (min-width: 768px) {
            font-size: $font-size-20;
        }

        border-radius: 10px;
    }
}

section#ecommerce-bitmaker {
    .ecommerce-extract-data {
        font-size: $font-size-24;

        .dynamic-text {
            font-weight: bold;
            color: #e47911;
        }

        @media (min-width: 768px) {
            font-size: $font-size-28;
        }
    }

    .ecommerce-main-heading {
        font-size: $font-size-28;
        line-height: 52.5px;

        @media (min-width: 768px) {
            font-size: $font-size-32;
        }
    }

    .ecommerce-info-box {
        background-color: #EFEFEF;
        padding: 20px;
        border-radius: 12px;
        max-width: 700px;
    }

    .ecommerce-features {
        display: grid;
        grid-template-areas:
            "top-left top-left . . top-right top-right"
            ". arrow-top-left arrow-top-left arrow-top-right arrow-top-right ."
            ". . main-icon main-icon . ."
            "left arrow-left main-icon main-icon arrow-right right"
            ". . main-icon main-icon . ."
            ". arrow-bottom-left arrow-bottom-left arrow-bottom-right arrow-bottom-right ."
            "bottom-left bottom-left . . bottom-right bottom-right";
        gap: 0px;
        justify-content: center;
        align-items: center;

        .ecommerce-feature {
            padding: 10px;

            @media (max-width: 992px) {
                padding: 3px;
            }

            p {
                @media (max-width: 768px) {
                    font-size: $font-size-12;
                }

                @media (max-width: 576px) {
                    font-size: $font-size-10;
                }
            }
        }

        .ecommerce-main-icon {
            grid-area: main-icon;
        }

        .ecommerce-feature-top-left {
            grid-area: top-left;
            align-self: end;
        }

        .ecommerce-feature-top-right {
            grid-area: top-right;
            align-self: end;
        }

        .ecommerce-feature-bottom-left {
            grid-area: bottom-left;
            align-self: start;
        }

        .ecommerce-feature-bottom-right {
            grid-area: bottom-right;
            align-self: start;
        }

        .ecommerce-feature-right {
            grid-area: right;
            justify-self: start;
        }

        .ecommerce-feature-left {
            grid-area: left;
            justify-self: end;
        }

        .main-icon-image {
            height: 100px;

            @media (max-width: 768px) {
                height: 12vw;
            }
        }

        .arrow {
            height: auto;

            @media (max-width: 992px) {
                width: 12vw;
            }

            @media (max-width: 768px) {
                width: 17vw;
            }
        }


        .arrow-top-left {
            grid-area: arrow-top-left;
            justify-self: end;
            align-self: end;
        }

        .arrow-top-right {
            grid-area: arrow-top-right;
            align-self: end;
        }

        .arrow-left {
            grid-area: arrow-left;
            justify-self: end;
        }

        .arrow-right {
            grid-area: arrow-right;
            justify-self: start;
        }

        .arrow-bottom-left {
            grid-area: arrow-bottom-left;
            justify-self: end;
            align-self: start;
        }

        .arrow-bottom-right {
            grid-area: arrow-bottom-right;
            align-self: start;
        }
    }

    .ecommerce-full-access {
        font-size: $font-size-22;
        line-height: 37.5px;

        .ecommerce-highlight-2 {
            color: #FF5F3C;
            font-weight: bold;
        }

        @media (min-width: 768px) {
            font-size: $font-size-26;
        }
    }

    .ecommerce-cta-box {
        background: linear-gradient(95.76deg, #FF5733 0.68%, #F8623D 21.12%, #838E56 68.31%, #00BF71 99.83%);
        padding: 90px;
        border-radius: 12px;
        color: white;
        display: grid;
        place-items: center;
        justify-content: center;

        p {
            font-size: $font-size-16;
            line-height: 30px;

            @media (min-width: 768px) {
                font-size: $font-size-20;
            }

        }

        h2 {
            font-size: $font-size-36;
            line-height: 48px;

            @media (min-width: 768px) {
                font-size: $font-size-40;
            }
        }
    }
}

.ecommerce-section-icon {
    max-width: 50px;

    @media (min-width: 768px) {
        max-width: 75px;
    }

    @media (min-width: 992px) {
        max-width: 100px;
    }
}

.ecommerce-section-title {
    font-size: $font-size-32;
    font-weight: 700;
    line-height: 52.5px;

    @media (min-width: 768px) {
        font-size: $font-size-36;
    }
}

.ecommerce-base-text {
    font-size: $font-size-16;
    line-height: 30px;

    @media (min-width: 768px) {
        font-size: $font-size-18;
    }
}

.ecommerce-clients-section-title {
    font-size: $font-size-28;
    color: #1E1E1E;
    line-height: 45px;
    letter-spacing: 0.02em;

    @media (min-width: 768px) {
        font-size: $font-size-32;
    }
}

.ecommerce-page-section {
    font-family: 'Poppins', sans-serif;
    padding: 20px 0;
}

.ecommerce-separator {
    border: 1px solid #D9D9D9;
    width: 80%;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.fade-in {
    animation: fadeIn 1s ease-in;
}

.fade-out {
    animation: fadeOut 1s ease-out;
}


.articles-left-square-1 {
    width: 6vw;
    height: 6vw;
    background-color: #00BF71;
    position: absolute;
    top: 0vw;
    left: 1vw;
}

.articles-left-square-2 {
    width: 3vw;
    height: 3vw;
    background-color: #00BF71;
    position: absolute;
    top: 6vw;
    left: 7vw;
}

.articles-left-square-3 {
    width: 1vw;
    height: 1vw;
    background-color: rgba(0, 191, 113, 0.39);
    position: absolute;
    top: 4vw;
    left: 9.5vw;
}

.clients-left-square-1 {
    width: 2vw;
    height: 2vw;
    background-color: #3AE6A0;
    position: absolute;
    bottom: 4vw;
    left: 1vw;
}

.clients-left-square-2 {
    width: 3vw;
    height: 3vw;
    background-color: #00BF71;
    position: absolute;
    bottom: 1vw;
    left: 4.2vw;
}

.clients-left-square-3 {
    width: 1vw;
    height: 1vw;
    background-color: #3AE6A0;
    position: absolute;
    bottom: 1vw;
    left: 8.1vw;
}

.competitor-left-squeare-1-d {
    width: 6vw;
    height: 6vw;
    background-color: #00BF71;
    position: absolute;
    bottom: 8vw;
    right: 4.5vw;
}

.competitor-left-squeare-2-d {
    width: 3vw;
    height: 3vw;
    background-color: #00BF71;
    position: absolute;
    bottom: 5vw;
    right: 1.5vw;
}

.competitor-left-squeare-3-d {
    width: 1vw;
    height: 1vw;
    background-color: rgba(0, 191, 113, 0.39);
    position: absolute;
    bottom: 9vw;
    right: 1vw;
}

.competitor-left-squeare-1-u {
    width: 2vw;
    height: 2vw;
    background-color: #3AE6A0;
    position: absolute;
    bottom: 18vw;
    right: 8.5vw;
}

.competitor-left-squeare-2-u {
    width: 3vw;
    height: 3vw;
    background-color: #00BF71;
    position: absolute;
    bottom: 15vw;
    right: 3.7vw;
}

.competitor-left-squeare-3-u {
    width: 1vw;
    height: 1vw;
    background-color: #3AE6A0;
    position: absolute;
    bottom: 15vw;
    right: 2vw;
}

.bitmaker-left-square-1 {
    width: 4vw;
    height: 4vw;
    background-color: #00BF71;
    position: absolute;
    top: 60vw;
    left: 2vw;
}

.bitmaker-left-square-2 {
    width: 2vw;
    height: 2vw;
    background-color: #00BF71;
    position: absolute;
    top: 64vw;
    left: 0vw;
}

.bitmaker-left-square-3 {
    width: 2vw;
    height: 2vw;
    background-color: #00BF71;
    position: absolute;
    top: 62vw;
    left: 7vw;
}

.bitmaker-op-left-square-1 {
    width: 1.2vw;
    height: 1.2vw;
    background-color: #3AE6A0;
    position: absolute;
    top: 60vw;
    left: 9vw;
}

.bitmaker-op-left-square-2 {
    width: 1vw;
    height: 1vw;
    background-color: #3AE6A0;
    position: absolute;
    top: 64.5vw;
    left: 7vw;
}

.bitmaker-op-left-square-3 {
    width: 1vw;
    height: 1vw;
    background-color: rgba(0, 191, 113, 0.39);
    position: absolute;
    top: 65.5vw;
    left: 2.5vw;
}

.bitmaker-right-square-1 {
    width: 4vw;
    height: 4vw;
    background-color: #FF5F3C;
    position: absolute;
    top: 60vw;
    right: 4vw;
}

.bitmaker-right-square-2 {
    width: 2vw;
    height: 2vw;
    background-color: #FF5F3C;
    position: absolute;
    top: 64vw;
    right: 2vw;
}

.bitmaker-right-square-3 {
    width: 1.6vw;
    height: 1.6vw;
    background-color: #FFA591;
    position: absolute;
    top: 63vw;
    right: 9vw;
}

.bitmaker-right-square-4 {
    width: 1.6vw;
    height: 1.6vw;
    background-color: #FFA591;
    position: absolute;
    top: 62.5vw;
    right: 0vw;
}

.bitmaker-left-orange-square-1 {
    width: 3vw;
    height: 3vw;
    background-color: #FF5F3C;
    position: absolute;
    top: 6vw;
    left: 2vw;
}

.bitmaker-left-orange-square-2 {
    width: 2vw;
    height: 2vw;
    background-color: #FF5F3C;
    position: absolute;
    top: 9vw;
    left: 0vw;
}

.bitmaker-left-orange-square-3 {
    width: 1vw;
    height: 1vw;
    background-color: #FFA591;
    position: absolute;
    top: 4.5vw;
    left: 0.6vw;
}

.inventory-right-orange-square-1 {
    width: 2.5vw;
    height: 2.5vw;
    background-color: #FF5F3C;
    position: absolute;
    bottom: 6vw;
    right: 1vw;
}

.inventory-right-orange-square-2 {
    width: 1.5vw;
    height: 1.5vw;
    background-color: rgba(255, 94, 59, 0.66);
    position: absolute;
    bottom: 4vw;
    right: 3.5vw;
}

.inventory-right-orange-square-3 {
    width: 1.2vw;
    height: 1.2vw;
    background-color: rgba(255, 94, 59, 0.66);
    position: absolute;
    bottom: 9.3vw;
    right: 1vw;
}
