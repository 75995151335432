// Font Mixins
@mixin serif-font {
  font-family: 'Droid Serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
@mixin script-font {
  font-family: 'Kaushan Script', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
@mixin body-font {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
@mixin heading-font {
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

@font-face {
  font-family: "PixelGosub";
  src: url("../fonts/PixelGosub.otf");
}

@font-face {
  font-family: "Chau Philomene One";
  src: url("../fonts/ChauPhilomeneOne-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Syne";
  src: url("../fonts/Syne-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans";
  src: url("../fonts/DMSans-VariableFont_opsz,wght.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-VariableFont_slnt,wght.ttf") format("truetype");
}
