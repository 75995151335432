// Styling for the masthead
.title-text {
  font-weight: 500;
  margin-bottom: 3rem;
  font-size: 80px;
  padding: 0 50px;
}

@media(max-width:768px) {
  .title-text {
    font-size: 45px;
    padding: 0 5px;
  }
}

header.newhead {
  box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.1);
  color: black;

  @media(max-width:767px) {
    .bm-logo {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  #landlogo {
    position: absolute;
    padding-top: 40px;
    padding-bottom: 50px;
  }

  @media(min-width:768px) {
    #landlogo {
      padding-left: 165px;
      padding-right: 100px;
    }
  }

  .intro-text {
    text-align: center;
    padding-top: 140px;
    padding-bottom: 90px;
    padding-left: 12%;
    padding-right: 12%;

    .intro-lead-in {
      font-size: 50px;
      font-weight: 400;
      line-height: 1.5em;
      margin-bottom: 40px;
      font-family: "PixelGosub";
    }

    .subtitle-in {
      font-size: 25px;
      font-weight: 300;
      @include heading-font;
    }

    .intro-heading {
      font-size: 17px;
      line-height: 22px;
      margin-bottom: 25px;
      @include serif-font;
    }

    @media(min-width:768px) {
      .intro-lead-in {
        font-size: 64px;
      }

      .subtitle-in {
        font-size: 36px;
      }

      .intro-heading {
        font-size: 20px;
      }

      padding-top: 180px;
    }
  }
}
