.green-btn {
  color: white;
  background-color: $primary;

  &:hover {
    color: $primary;
    border-color: $primary !important;
    background-color: white;
  }
}

.orange-btn {
  color: white;
  background-color: #FF5F3C;

  &:hover {
    color: #FF5F3C;
    border-color: #FF5F3C !important;
    background-color: white;
  }
}

.email-logo {
  font-size: 40px;
  margin-right: 5px;
  margin-bottom: 0;
  color: white;
  width: 50px;
  height: 50px;
}

ul.new-social-buttons {
  margin: 0;

  li {
    a {
      font-size: 35px;
      line-height: 60px;
      display: block;
      width: 55px;
      height: 55px;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      transition: all 0.3s;
      color: rgba(0, 191, 113, 1);
      border-radius: 15%;
      outline: none;
      background-color: white;

      &:active,
      &:focus,
      &:hover {
        background-color: $gray-900;
        color: white;
      }
    }
  }

  @media (max-width: 991px) {
    li a {
      font-size: 30px;
      width: 50px;
      height: 50px;
      line-height: 55px;
    }
  }
}

.contact-form {
  .help-block {
    font-weight: 500;
  }

  .form-group {
    margin-bottom: 20px;

    input,
    textarea [type="text"] {
      @include heading-font;
    }

    input,
    textarea {
      padding: 10px;
    }

    input.form-control {
      height: auto;
    }

    textarea.form-control {
      height: 120px;
      min-height: 45px;
      max-height: 445px;
    }
  }

  .form-control:focus {
    border-color: $gray-800;
    box-shadow: none;
  }

  ::-webkit-input-placeholder {
    font-weight: 700;
    color: $gray-400;
    @include heading-font;
  }

  :-moz-placeholder {
    font-weight: 700;
    color: $gray-400;
    @include heading-font;
  }

  ::-moz-placeholder {
    font-weight: 700;
    color: $gray-400;
    @include heading-font;
  }

  :-ms-input-placeholder {
    font-weight: 700;
    color: $gray-400;
    @include heading-font;
  }
}
