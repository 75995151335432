// Styling the blog section

#blog-card {
  transition: all 0.25s ease;
  padding: 10px 10px;
  transform: scale(1);
  line-height: normal;
  border-radius: 0.5rem;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: scale(1.05);
    -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
  }

  .title {
    margin: 0.5rem !important;
    color: $primary !important;
  }

  .content {
    margin: 0.5rem;
    color: $gray-800 !important;
    @include heading-font;
  }

  .image {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    object-fit: contain;
  }
}

.pagination-row{
  padding-top: 10px;
  a, span {
    font-size: 0.95em;
  }
  .page-number {
    color: $gray-900 !important;
    font-weight: 400 !important;
    margin: 10px 1px 0px 1px;
    #current {
      font-weight: 700 !important;
      color: $primary;
    }
  }
  .btn-pag {
    font-weight: 700 !important;
    background-color: $primary !important;
    color: white !important;
    margin-top: 10px;
    border-radius: 93px;
    &:hover {
      color: $primary !important;
      border-color: $primary !important;
      background-color: white !important;
    }
  }
}
@media(min-width:991px) {
  .pagination-row{
    a, span {
      font-size: 1.03em;
    }
    .page-number {
      margin: 10px 10px 0px 10px;
    }
  }
}
