// Global styling for this template
html, body {
  height: 100%;
}

body {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  @include body-font;
}

p {
  line-height: 1.5;
}
.p-b {
  font-size: 1.30em;
}
.p-md {
  font-size: 1.15em;
}
@media(min-width:991px) {
  .p-md {
    text-align: justify;
  }
}

.no-margin {
  margin: 0 !important;
}

a {
  color: $gray-900;
  &:hover {
    color: $primary;
    text-decoration: none;
  }
}
.post-body {
  a {
    color: $primary;
    &:hover {
      color:  $gray-900;
      text-decoration: none;
    }
  }
  img {
    width: 100%;
    height: auto;
    max-height: 500px;
    display: block;
    margin: 0 auto;
  }
  .blog-info {
    p {
      margin-bottom: 0 !important;
      font-size: 0.9em;
    }
  }
}

li {
  margin-top: 5px;
  margin-bottom: 5px;
}

.text-primary {
  color: $primary !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  @include heading-font;
}

.page-section {
  padding: 40px 0;
  h2.section-heading {
    margin-bottom: 20px;
    font-size: 35px;
    font-family: "PixelGosub";
  }
  @media(min-width:768px) {
    h2.section-heading {
      font-size: 40px;
    }
    padding: 60px 0;
  }
  h3.section-subheading {
    font-size: 16px;
    font-weight: 400;
    font-style: italic;
    margin-bottom: 75px;
    text-transform: none;
    @include serif-font;
  }
}

@media(min-width:768px) {
  section {
    padding: 150px 0;
  }
}

// Highlight color customization
::-moz-selection {
  background: $primary;
  text-shadow: none;
}

::selection {
  background: $primary;
  text-shadow: none;
}

img::selection {
  background: transparent;
}

img::-moz-selection {
  background: transparent;
}

#pagecontainer {
  padding-top: 150px;
  padding-bottom: 50px;
  flex: 1 0 auto;
}

@media only screen and (max-width: 991px) {
  #pagecontainer {padding-top: 100px;}
}

#post {
  #postcontainer {
    padding: 50px 30px 100px 30px;
    .post-body {
      color: $gray-900;
      font-size: 1.05em;
      @include heading-font;
      //font-family: charter, Georgia, Cambria, "Times New Roman", Times, serif;
      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
	line-height: 28px !important;
	margin-bottom: 20px;
      }
    }
  }
  #post-title {
    //color: $primary;
    color: white;
    font-family: "PixelGosub";
    font-size: 45px;
    padding: 150px 30px 50px 30px;
  }
  .post-back{
    background-image:
      url("#{$header-image}"),
      linear-gradient(105.96deg, #E62900 0%, #C56C00 27.08%, #9A9200 52.6%, #68AC24 75.52%, #00BF71 100%); // maybe?
    background-position: center;
    background-repeat: no-repeat;
    //background-size: cover;
  }
}
@media(min-width:991px) {
  #post {
    #postcontainer{
      padding: 70px 100px 100px 100px;
      .post-body {
	font-size: 1.10em;
	text-align: justify; // maybe no
	p,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
	  line-height: 32px !important;
	}
      }
    }
    #post-title {
      //color: $primary;
      font-size: 60px;
      padding: 150px 100px 50px 100px;
    }
  }
}

.narrow-content {
  padding: 0px 5%;
}
@media(min-width:991px) {
  .narrow-content {
    padding: 0px 20%;
  }
}
