// Styling for the services section
.service-col {
  padding: 15px;
}

.service-card {
  height: 100%;
  padding: 25px;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
}

.active-card {
  &:hover {
    transform: scale(1.01);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
  }
}

.service-heading {
  margin: 15px 0;
  text-transform: none;
  font-size: 1.5rem;
}

.service-desc {
  @include body-font;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 1rem;
}

.cat_circle {
  background: $primary;
  border-radius: 100%;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cat_circle img {
  width: 60%;
  height: auto;
}

.p-big {
  font-size: 1.05em;
}

// Styling for the numbers section

.number-par {
  padding-left: 5%;
  padding-right: 5%;
}

.big-number {
  font-size: 60px;
  font-weight: 700;
  @include heading-font;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

.number-desc {
  font-weight: 700;
  @include heading-font;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}
