.blocks-square-top-left-1 {
  width: 4vw;
  height: 4vw;
  background-color: #FF5F3C;
  position: absolute;
  top: 3vw;
  left: 8vw;
}

.blocks-square-top-left-2 {
  width: 8vw;
  height: 8vw;
  background-color: #FF5F3C;
  position: absolute;
  top: 7vw;
  left: 0;
}

.blocks-square-top-left-3 {
  width: 2vw;
  height: 2vw;
  background-color: #FFA591;
  position: absolute;
  top: 16vw;
  left: 8vw;
}

.blocks-square-bottom-right-1 {
  width: 4vw;
  height: 4vw;
  background-color: #00BF71;
  position: absolute;
  bottom: 8vw;
  right: 0vw;
}

.blocks-square-bottom-right-2 {
  width: 8vw;
  height: 8vw;
  background-color: #00BF71;
  position: absolute;
  bottom: 0vw;
  right: 4vw;
}

.blocks-spider-image-container {
  position: absolute;
  bottom: 1vw;
  left: 1vw;
}

.spider-image {
  width: 80%;
  height: auto;

  @media (max-width: 767px) {
    width: 70%;
  }
}

.get-started-square-top-right {
  width: 2vw;
  height: 2vw;
  background-color: #3AE6A0;
  position: absolute;
  top: 2vw;
  right: 2vw;
}

.get-started-square-bottom-left-1 {
  width: 3vw;
  height: 3vw;
  background-color: #87E7C0;
  position: absolute;
  bottom: 6vw;
  left: 2vw;
}

.get-started-square-bottom-left-2 {
  width: 4vw;
  height: 4vw;
  background-color: #00BF71;
  position: absolute;
  bottom: 2vw;
  left: 6vw;
}

.get-started-square-bottom-left-3 {
  width: 2vw;
  height: 2vw;
  background-color: #87E6C0;
  position: absolute;
  bottom: 2vw;
  left: 11vw;
}

.header-square-left-1 {
  width: 4vw;
  height: 4vw;
  background-color: #00BF71;
  position: absolute;
  top: 28vw;
  left: 0;
}

.header-square-left-2 {
  width: 8vw;
  height: 8vw;
  background-color: #00BF71;
  position: absolute;
  top: 20vw;
  left: 4vw;
}

.header-square-left-3 {
  width: 2vw;
  height: 2vw;
  background-color: #00BF7163;
  position: absolute;
  top: 31vw;
  left: 6vw;
}

.header-square-left-4 {
  width: 4vw;
  height: 4vw;
  background-color: #00BF71;
  position: absolute;
  top: 24vw;
  left: 14vw;
}

.header-square-left-5 {
  width: 2vw;
  height: 2vw;
  background-color: #3AE6A0;
  position: absolute;
  top: 29vw;
  left: 14vw;
}

.header-square-left-6 {
  width: 3vw;
  height: 3vw;
  background-color: #3AE6A0;
  position: absolute;
  top: 20vw;
  left: 18vw;
}

.header-square-right-1 {
  width: 8vw;
  height: 8vw;
  background-color: #FF5F3C;
  position: absolute;
  bottom: 12vw;
  right: 4vw;
}

.header-square-right-2 {
  width: 4vw;
  height: 4vw;
  background-color: #FF5F3C;
  position: absolute;
  bottom: 20vw;
  right: 0;
}

.header-square-right-3 {
  width: 2vw;
  height: 2vw;
  background-color: #FFA591;
  position: absolute;
  bottom: 8vw;
  right: 2vw;
}

.vertical-line {
  height: 420px;
  width: 5px;
  background-color: white;
  margin: 0 auto 20px;
}

.footer-spider-container {
  position: relative;
}

.footer-spider {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: auto;
}

.footer-logo{
  max-width: 100%;
  height: auto;
}

.article-card {
  background-color: #1E1E1E;
  color: #ffffff;
  border-radius: 15px;
  min-height: 440px;

  @media (max-width: 768px) {
    min-height: 470px;
  }  

  img {
    border-radius: 15px;
    margin-bottom: 15px;
  }
}
