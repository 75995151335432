// Styling for the team section
.team-member {
  margin-bottom: 30px;
  text-align: center;
  img {
    width: 225px;
    height: 225px;
    border: 7px solid fade-out($black, 0.9);
  }
  h4 {
    margin-top: 25px;
    margin-bottom: 0;
    text-transform: none;
  }
  p {
    margin-top: 0;
    font-weight: bold;
  }
}

@media(max-width:767px) {   
  .div-phone{
    padding-bottom: 18px;
  }
}

ul.team-buttons {
  margin-bottom: 0;
  li {
    a {
      font-size: 20px;
      line-height: 50px;
      display: block;
      width: 50px;
      height: 50px;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      transition: all 0.3s;
      color: white;
      border-radius: 100%;
      outline: none;
      background-color: $primary;
      &:active,
      &:focus,
      &:hover {
        background-color: $gray-900;
      }
    }
  }
}
