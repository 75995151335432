// Styling for the contact section
section#contact {
  background-image:
    url("#{$header-image}"),
    linear-gradient(105.96deg, #E62900 0%, #C56C00 27.08%, #9A9200 52.6%, #68AC24 75.52%, #00BF71 100%);
  background-repeat: no-repeat;
  background-position: center;
  .section-heading {
    color: $white;
  }
  .section-subheading {
    color: white;
    line-height: 1.5;
    font-size: 1.5em;
  }
  .help-block {
    font-weight: 500;
  }
  .form-group {
    margin-bottom: 25px;
    input, textarea [type="text"] {
      @include heading-font;
    }
    select {
      @include heading-font;
    }
    input,
    select,
    textarea {
      padding: 10px;
    }
    select.form-control {
      height: auto;
    }
    input.form-control {
      height: auto;
    }
    textarea.form-control {
      height: 175px;
      min-height: 100px;
      max-height: 500px;
    }
  }
  .form-control:focus {
    border-color: $gray-800;
    box-shadow: none;
  }
  ::-webkit-input-placeholder {
    font-weight: 700;
    color: $gray-400;
    @include heading-font;
  }
  :-moz-placeholder {
    font-weight: 700;
    color: $gray-400;
    @include heading-font;
  }
  ::-moz-placeholder {
    font-weight: 700;
    color: $gray-400;
    @include heading-font;
  }
  :-ms-input-placeholder {
    font-weight: 700;
    color: $gray-400;
    @include heading-font;
  }
  .container {
    padding-top: 100px;
  }
  @media(min-width:768px) {
    .container {
      padding-top: 150px;
    }
    .section-subheading {
      padding-right: 10%;
    }
  }
}

.contact-footer {
  padding-top: 15px;
  color: white;
  font-size: 1.2em;
  a {
    color: $primary;
    font-weight: bold;
    text-decoration: underline;
  }
  a:hover{
    color: $gray-900;
  }
}

.recaptcha-container {
  width: 100%;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
}
