// Styling for the masthead
header.masthead {
  color: white;
  background-image:
    url("#{$header-image}"),
    linear-gradient(105.96deg, #E62900 0%, #C56C00 27.08%, #9A9200 52.6%, #68AC24 75.52%, #00BF71 100%);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center;
  @media(max-width:767px) {
    .bm-logo{
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  #landlogo{
    position: absolute;
    padding-top: 40px;
    padding-bottom: 50px;
  }
  @media(min-width:768px) {
    #landlogo{
      padding-left: 165px;
      padding-right: 100px;
    }
  }
  .intro-text{
    text-align: center;
    padding-top: 140px;
    padding-bottom: 90px;
    padding-left: 12%;
    padding-right: 12%;
    .intro-lead-in {
      font-size: 50px;
      font-weight: 400;
      line-height: 1.5em;
      margin-bottom: 40px;
      font-family: "PixelGosub";
    }
    .subtitle-in {
      font-size: 25px;
      font-weight: 300;
      @include heading-font;
    }
    .intro-heading {
      font-size: 17px;
      line-height: 22px;
      margin-bottom: 25px;
      @include serif-font;
    }
    @media(min-width:768px){
      .intro-lead-in {
	font-size: 64px;
      }
      .subtitle-in {
	font-size: 36px;
      }
      .intro-heading {
	font-size: 20px;
      }
      padding-top: 180px;
    }
  }
}
